import styles from './index.module.scss';
import closeIcon from '../../assets/icons/close.svg';

export default function VideoModal({handleCloseModal,src}) {
  return (
    <div onClick={handleCloseModal} className={styles['modal']}>
      <div onClick={(e)=>e.stopPropagation()} className={styles['modal-container']}>
        <div className={styles['modal-content']}>
          <img className={styles['modal-icon']} onClick={handleCloseModal} src={closeIcon} alt='close' />
          <video controls autoPlay>
            <source src={src} type='video/mp4' />
          </video>
        </div>
      </div>
    </div>
  )
}
