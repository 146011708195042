import { useForm } from 'react-hook-form';

export function useHookForm(props) {
	const {
		register,
		handleSubmit,
		resetField,
		formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
		setValue,
		getValues,
		trigger,
		clearErrors,
	} = useForm({ mode: 'onSubmit' });

	const fullNameRegister = register('full_name', {
		onChange: (e) => {
			clearErrors('full_name');
			const full_name = e.target.value;
			const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
			const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
			if (full_nameRegex.test(displayValue)) {
				setValue('full_name', displayValue);
			} else {
				setValue('full_name', displayValue.trim());
			}
			if (!e.target.value) {
				resetField('full_name');
			}
		},
		required: {
			value: true,
			message: 'Camper full name is required',
		},
		maxLength: {
			value: 20,
			message: 'Max Characters has been exceeded',
		},
	});

	const schoolNameRegister = register('school_name', {
		onChange: (e) => {
			clearErrors('school_name');
			const full_name = e.target.value;
			const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
			const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
			if (full_nameRegex.test(displayValue)) {
				setValue('school_name', displayValue);
			} else {
				setValue('school_name', displayValue.trim());
			}
			if (!e.target.value) {
				resetField('school_name');
			}
		},
		required: {
			value: true,
			message: 'Camper school name is required',
		},
		maxLength: {
			value: 20,
			message: 'Max Characters has been exceeded',
		},
	});

	const ageRegister = register('age', {
		onChange: (e) => {
			clearErrors('age');
			const age = e.target.value;
			const trimmedAge = age.replace(/\D/g, '');
			setValue('age', trimmedAge);
		},
		required: {
			value: true,
			message: 'Age is required',
		},
		validate: (value) => {
			return (Number(value) >= 4 && Number(value) <= 14) || 'Camper age must be between 4 and 14';
		},
	});

	const parentFullNameRegister = register('parent_full_name', {
		onChange: (e) => {
			clearErrors('parent_full_name');
			const full_name = e.target.value;
			const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
			const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
			if (full_nameRegex.test(displayValue)) {
				setValue('parent_full_name', displayValue);
			} else {
				setValue('parent_full_name', displayValue.trim());
			}
			if (!e.target.value) {
				resetField('parent_full_name');
			}
		},
		required: {
			value: true,
			message: 'Guardian full name is required',
		},
		maxLength: {
			value: 20,
			message: 'Max Characters has been exceeded',
		},
	});

	const parentRelationshipRegister = register('parent_relationship', {
		onChange: (e) => {
			clearErrors('parent_relationship');
			const full_name = e.target.value;
			const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
			const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
			if (full_nameRegex.test(displayValue)) {
				setValue('parent_relationship', displayValue);
			} else {
				setValue('parent_relationship', displayValue.trim());
			}
			if (!e.target.value) {
				resetField('parent_relationship');
			}
		},
		required: {
			value: true,
			message: 'Guardian relation is required',
		},
		maxLength: {
			value: 20,
			message: 'Max Characters has been exceeded',
		},
	});

	const parentPhoneRegister = register('parent_phone_number', {
		onChange: (e) => {
			clearErrors('parent_phone_number');
			const phoneNumber = e.target.value;
			const trimmedNumber = phoneNumber.replace(/\D/g, '');
			setValue('parent_phone_number', trimmedNumber);
		},
		required: {
			value: true,
			message: 'mobile number is required',
		},
	});

	const parentEmailRegister = register('parent_email', {
		onChange: (e) => {
			clearErrors('parent_email');
			setValue('parent_email', e.target.value);
			clearErrors('parent_email');
			if (!e.target.value) {
				resetField('parent_email');
			}
		},
		required: {
			value: true,
			message: 'Guardian email is Required',
		},
		validate: (value) => {
			if (!value) {
				return true;
			} else {
				const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
				return regex.test(value) || 'Guardiam email is not valid';
			}
		},
	});

	const residencyOtherRegister = register('residency_other', {
		onChange: (e) => {
			clearErrors('residency_other');
			const full_name = e.target.value;
			const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
			const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
			if (full_nameRegex.test(displayValue)) {
				setValue('residency_other', displayValue);
			} else {
				setValue('residency_other', displayValue.trim());
			}
			if (!e.target.value) {
				resetField('residency_other');
			}
		},
	});

	const participatedExtraRegister = register('participated_extra', {
		onChange: (e) => {
			clearErrors('participated_extra');
			const full_name = e.target.value;
			const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
			const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
			if (full_nameRegex.test(displayValue)) {
				setValue('participated_extra', displayValue);
			} else {
				setValue('participated_extra', displayValue.trim());
			}
			if (!e.target.value) {
				resetField('participated_extra');
			}
		},
	});

	const allergiesExtraRegister = register('allergies_extra', {
		onChange: (e) => {
			clearErrors('allergies_extra');
			const full_name = e.target.value;
			const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
			const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
			if (full_nameRegex.test(displayValue)) {
				setValue('allergies_extra', displayValue);
			} else {
				setValue('allergies_extra', displayValue.trim());
			}
			if (!e.target.value) {
				resetField('allergies_extra');
			}
		},
	});

	const specialExtraRegister = register('special_extra', {
		onChange: (e) => {
			clearErrors('special_extra');
			const full_name = e.target.value;
			const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
			const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
			if (full_nameRegex.test(displayValue)) {
				setValue('special_extra', displayValue);
			} else {
				setValue('special_extra', displayValue.trim());
			}
			if (!e.target.value) {
				resetField('special_extra');
			}
		},
	});

	const medsExtraRegister = register('meds_extra', {
		onChange: (e) => {
			clearErrors('meds_extra');
			const full_name = e.target.value;
			const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
			const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
			if (full_nameRegex.test(displayValue)) {
				setValue('meds_extra', displayValue);
			} else {
				setValue('meds_extra', displayValue.trim());
			}
			if (!e.target.value) {
				resetField('meds_extra');
			}
		},
	});

	return {
		handleSubmit,
		errors,
		isDirty,
		isValid,
		fullNameRegister,
		ageRegister,
		schoolNameRegister,
		parentFullNameRegister,
		parentRelationshipRegister,
		parentPhoneRegister,
		parentEmailRegister,
		residencyOtherRegister,
		participatedExtraRegister,
		allergiesExtraRegister,
		specialExtraRegister,
		medsExtraRegister,
		getValues,
		dirtyFields,
		isSubmitting,
		clearErrors,
		setValue,
		trigger,
	};
}
