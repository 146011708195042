import styles from './index.module.scss';

export function FormInput(props) {
  const {
    formRegister,
    icon,
    name,
    placeholder,
    label,
    value,
    maxLength,
    error,
    inputMode,
    disabled,
  } = props;

  return (
    <div className={styles['container']}>
      {label && 
        <label htmlFor={name} className={'input-label'}>
          {label}
        </label>
      }
      <div
        className={[
          styles['input-container'],
          error ? styles['input-container-error'] : ''
        ].join(' ')}
      >
        <input
          id={name}
          name={name}
          type={'text'}
          {...formRegister}
          placeholder={placeholder ? placeholder : ''}
          maxLength={maxLength}
          autoComplete="off"
          value={value}
          inputMode={inputMode}
          disabled={disabled}
        />
        {icon && icon}
      </div>
      {error && <p className={styles['form-error']}>{error?.message}</p>}
    </div>
  );
}

export default FormInput;