import styles from './index.module.scss'; 
import registerFirst from '../../assets/images/register-first.png';
import registerSecond from '../../assets/images/register-second.png';
import { useHookForm } from '../../hooks/hook-form';
import FormInput from '../../components/formInput';
import {FormCheckbox} from '../../components/formCheckbox';
import { Radio } from '../../components/radio';
import { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dateIcon from '../../assets/icons/date.svg';
import { Box, Chip, MenuItem, OutlinedInput, Select } from '@mui/material';
import { Helmet } from 'react-helmet';
import Loader from '../../components/loader';

const availableExperience = [
  '2 hours slot',
  '1 Day Camp (5 Hours)',
  '3 Days Camp (5 Hours Daily)'
]

const availableSlots = {
  rest: [
    'from 12 pm to 2 pm',
    'from 2 pm to 4 pm',
    'from 4 pm to 6 pm',
  ],
  friday: [
    'from 2 pm to 4 pm',
    'from 4 pm to 6 pm',
    'from 6 pm to 8 pm',
  ],
  threeDays: [
    'Aug 13, 14, & 15 (Tuesday to Thursday)',
    'Aug 16, 17 & 18 (Friday to Sunday)'
  ]
}

const grades = [
  'Kindergarten 1 (KG1)',
  'Kindergarten 2 (KG2)',
  'Grade 1',
  'Grade 2',
  'Grade 3',
  'Grade 4',
  'Grade 5',
  'Grade 6',
  'Grade 7',
  'Grade 8',
]

export default function Register({setSnackbar}) {
  const [activity, setActivity] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState([]);
  const [participated, setParticipated] = useState();
  const [neoWell, setNeoWell] = useState();
  const [openDate, setOpenDate] = useState(false);
  const [selectedSlots, setSelectedSlots] = useState('');
  const [selectedExperienceSlots, setSelectedExperienceSlots] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [date, setDate] = useState(null);
  const [checkboxes, setCheckboxes] = useState(null);
  const [residency, setResidency] = useState('');
  const [trigger, setTrigger] = useState(false);
  const [allergies, setAllergies] = useState();
  const [meds, setMeds] = useState();
  const [special, setSpecial] = useState();
  const [media, setMedia] = useState();
  const [loading, setLoading] = useState(false);

  const {
    fullNameRegister,
    ageRegister,
    parentFullNameRegister,
    parentEmailRegister,
    parentPhoneRegister,
    parentRelationshipRegister,
    schoolNameRegister,
    residencyOtherRegister,
    participatedExtraRegister,
    allergiesExtraRegister,
    specialExtraRegister,
    medsExtraRegister,
    isValid,
    isDirty,
    errors,
    handleSubmit,
  } = useHookForm();

  const handleGetActivity = async () => {
    try {
      const response = await fetch('https://app.stempowers.me/api/camp_manager/activity_preferences');
      const data = await response.json();
      setActivity(data.results);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const isDisabled = () => {
    return !isDirty || !isValid || !selectedActivity.length || (selectedExperienceSlots === '2 hours slot' && !date && !selectedSlots) || (selectedExperienceSlots === '1 Day Camp (5 Hours)' && !date) || (selectedExperienceSlots === '3 Days Camp (5 Hours Daily' && !selectedSlots) || !selectedGrade || !residency || participated === undefined || allergies === undefined || meds === undefined || special === undefined || media === undefined;
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSlots(value);
  };
  
  const handleExperienceChange = (event) => {
    const {
      target: { value },
    } = event;
    setDate(null);
    setSelectedSlots('');
    setSelectedExperienceSlots(value);
  };

  const handleGradeChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedGrade(value);
  }
  const handleCheckboxesChange = (event) => {
    const {
      target: { checked,name },
    } = event;
    setCheckboxes(prev => ({ ...prev, [name]: checked}));
  }
  const handleActivityCheckboxesChange = (event) => {
    const {
      target: { checked,name },
    } = event;
    setSelectedActivity(prev => {
      if (checked) {
        if (selectedActivity.includes(name)) return prev;
        return [...selectedActivity, name];
      } else {
        return selectedActivity.filter(item => item !== name);
      }
    });
  }

  const handleFormSubmit = async (data) => {
    if (isDisabled()) return;
    setLoading(true);
    try {
      const payload = {
        name: data.full_name,
        age: data.age,
        school_name: data.school_name,
        grade_fall_2024: selectedGrade,
        parent_name: data.parent_full_name,
        parent_email: data.parent_email,
        parent_phone_number: data.parent_phone_number,
        parent_relationship: data.parent_relationship,
        emergency_name: data.parent_full_name,
        emergency_phone_number: data.parent_phone_number,
        emergency_relationship: data.parent_relationship,
        has_previous_camps: participated,
        previous_camps_details: data.participated_extra,
        has_allergies: allergies,
        allergies_setails: data.allergies_extra,
        need_special_care: special,
        special_care_details: data.special_extra,
        need_meds: meds,
        meds_details: data.meds_extra,
        day: dayjs(date).format('YYYY-MM-DD'),
        time_slot: selectedSlots,
        activity_preferneces: selectedActivity,
        ...(neoWell && {neowell_sports_activity: neoWell}),
        media_permission: media,
      };
      const response = await fetch('https://app.stempowers.me/api/camp_manager/register_camper', {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 201) {
        setSnackbar({
          display: true,
          message: 'Registration successful',
          variant: 'success',
        })
      } else {
        setSnackbar({
          display: true,
          message: 'Registration failed try again later',
          variant: 'error',
        })
      }
    } catch (error) {
      setSnackbar({
        display: true,
        message: 'Registration failed try again later',
        variant: 'error',
      })
    }
    setLoading(false);
  };
  const chooseParticipated = (value) => {
    setParticipated(value);
  }
  const chooseNeoWell = (value) => {
    setNeoWell(value);
  }
  const chooseResidency = (value) => {
    setResidency(value);
  }
  const chooseAllergies = (value) => {
    setAllergies(value);
  }
  const chooseMeds = (value) => {
    setMeds(value);
  }
  const chooseSpecial = (value) => {
    setSpecial(value);
  }
  const chooseMedia = (value) => {
    setMedia(value);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    handleGetActivity();
  }, []);

  return (
    <>
      <Helmet>
				<title>STEMPOWERS | REGISTER</title>
			</Helmet>
      <div className={styles['register']}>
        <h2 className={styles['register-title']}>
          <img className={styles['register-first']} src={registerFirst} alt='' />
          Stem summer program 2024
          <img className={styles['register-second']} src={registerSecond} alt='' />
        </h2>
        <p className={styles['register-text']}>
          You can join starting from July 1st until August 31st, 2024.
        </p>
        <p className={styles['register-text']}>
        Camp location: VIDA Marassi Marina Resort, Alamin
        </p>
        <form className={styles['register-form']} noValidate onSubmit={handleSubmit(handleFormSubmit)}>
          <div className={styles['register-form-input-container']}>
            <FormInput maxLength={20} formRegister={fullNameRegister} error={errors['full_name']} label={'Camper Full Name'} placeholder={'Enter child full name'}  />
          </div>
          <div className={styles['register-form-input-container']}>
            <FormInput maxLength={2} formRegister={ageRegister} error={errors['age']} label={'Age'} placeholder={'Age'} />
            <div className={styles['options-container']}>
                <p className={styles['options-label']}>Grade</p>
                <Select
                  labelId="demo-multiple-chip-label-grade"
                  id="demo-multiple-chip-grade"
                  sx={{
                    width: '100%',
                    minHeight: '6rem',
                    background: '#fff',
                    border: '4px solid',
                    borderRadius: '2.125rem',
                    outline: 'none !important',
                    fontSize: '1.5rem !important',
                    borderColor: trigger && !selectedGrade ? '#fb5b5b' : '#000c78',
                    '& *': {
                      fontFamily: 'Schoolbell !important',
                      fontSize: '1.5rem !important',
                      border: '0 !important',
                      color: '#000c78 !important'
                    },
                    '& p': {
                      fontSize: '1.75rem !important',
                      color: '#b3b7bf !important'
                    },
                  }}
                  displayEmpty
                  value={selectedGrade}
                  onChange={handleGradeChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => {
                    if (selected.length === 0) 
                      return (
                        <p>
                          Select grade
                        </p>
                      );
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: 0.5 }}>
                        <Chip key={selected} label={selected} />
                      </Box>
                    )
                  }}
                >
                  {grades.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      sx={{
                        fontFamily: 'Schoolbell !important',
                        fontSize: '1.5rem !important',
                        border: '0 !important',
                        color: '#000c78 !important'
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
              </Select>
              {trigger && !selectedGrade &&
                <p className={styles['form-error']}>Grade is required</p>
              }
            </div>
          </div>
          <div className={styles['register-form-input-container']}>
            <FormInput maxLength={20} formRegister={schoolNameRegister} error={errors['school_name']} label={'Camper School name'} placeholder={'Camper school name'}  />
          </div>
          <div className={styles['register-form-input-container']}>
            <FormInput maxLength={20} formRegister={parentFullNameRegister} error={errors['parent_full_name']} label={'Guardian Full Name'} placeholder={'Enter full name'}  />
          </div>
          <div className={styles['register-form-input-container']}>
            <FormInput maxLength={40} formRegister={parentEmailRegister} error={errors['parent_email']} label={'Guardian Email'} placeholder={'Enter email'}  />
          </div>
          <div className={styles['register-form-input-wrapper']}>
            <div className={styles['register-form-input-container']}>
              <FormInput maxLength={20} formRegister={parentRelationshipRegister} error={errors['parent_relationship']} label={'Relationship to Camper'} placeholder={'Relationship to camper'}  />
              <FormInput maxLength={20} formRegister={parentPhoneRegister} error={errors['parent_phone_number']} label={'Guardian Phone Number'} placeholder={'Contact Phone Number'} />
            </div>
            <FormCheckbox name={'isEmergencySame'} onChange={handleCheckboxesChange} label={'Emergency Contact Information same as guardian information'} />
          </div>
          {activity && 
            <div className={styles['register-form-input-wrapper']}>
              <p className={styles['register-form-text-small']}>
                Camper residency
              </p>
              <div className={styles['register-form-input-container-radio-column']}>
                <Radio handleClick={chooseResidency} selected={residency} id={'residency-1'} value={'A Guest at Vida Marassi Marina Resort.'} label={'A Guest at Vida Marassi Marina Resort.'} name={'residency'} />
                <Radio handleClick={chooseResidency} selected={residency} id={'residency-2'} value={'A Guest at Address Beach  Resort Marassi.'} label={'A Guest at Address Beach  Resort Marassi.'} name={'residency'} />
                <Radio handleClick={chooseResidency} selected={residency} id={'residency-3'} value={'A Resident at Marassi.'} label={'A Resident at Marassi.'} name={'residency'} />
                <Radio handleClick={chooseResidency} selected={residency} id={'residency-4'} value={'A Guest at Al Alamein Hotel.'} label={'A Guest at Al Alamein Hotel.'} name={'residency'} />
                <Radio handleClick={chooseResidency} selected={residency} id={'residency-5'} value={'Other'} label={'Other'} name={'residency'} />
              </div>
              {trigger && !residency &&
                <p className={styles['form-error']}>* Residency is required</p>
              }
              {residency === 'Other' &&
                <FormInput maxLength={100} formRegister={residencyOtherRegister} placeholder={'Please Specify'} />
              }
            </div>
          }
          <div className={styles['register-form-input-wrapper']}>
            <p className={styles['register-form-text']}>Has your child participated in ANY camp before?</p>
            <div className={styles['register-form-input-container-radio']}>
              <Radio handleClick={chooseParticipated} selected={participated} id={'participated-yes'} value={true} label={'Yes'} name={'participated'} />
              <Radio handleClick={chooseParticipated} selected={participated} id={'participated-no'} value={false} label={'No'} name={'participated'} />
            </div>
            {trigger && participated === undefined && (
              <p className={styles['form-error']}>* you must select an option</p>
            )}
            {participated &&
              <FormInput maxLength={100} formRegister={participatedExtraRegister} placeholder={'Please Specify'} />
            }
          </div>
          <div className={styles['register-form-input-wrapper']}>
            <p className={styles['register-form-text']}>Please book your preferred experience</p>
            <div className={styles['register-form-input-container']}>
              <div className={styles['options-container']}>
              <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  sx={{
                    width: '100%',
                    minHeight: '6rem',
                    background: '#fff',
                    border: '4px solid #000c78',
                    borderRadius: '2.125rem',
                    outline: 'none !important',
                    fontSize: '1.5rem !important',
                    borderColor: trigger && !selectedExperienceSlots ? '#fb5b5b' : '#000c78',
                    '& *': {
                      fontFamily: 'Schoolbell !important',
                      fontSize: '1.5rem !important',
                      border: '0 !important',
                      color: '#000c78 !important'
                    },
                    '& p': {
                      fontSize: '1.75rem !important',
                      color: '#b3b7bf !important'
                    }
                  }}
                  displayEmpty
                  value={selectedExperienceSlots}
                  onChange={handleExperienceChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => {
                    if (selected.length === 0) 
                      return (
                        <p>
                          Select Experience
                        </p>
                      );
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: 0.5 }}>
                        <Chip key={selected} label={selected} />
                      </Box>
                    )
                  }}
                >
                  {availableExperience.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      sx={{
                        fontFamily: 'Schoolbell !important',
                        fontSize: '1.5rem !important',
                        border: '0 !important',
                        color: '#000c78 !important'
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                {trigger && !selectedExperienceSlots &&
                  <p className={styles['form-error']}>* Experience is required</p>
                }
              </div>
            </div>
            {selectedExperienceSlots && selectedExperienceSlots === '2 hours slot' &&
              <div className={styles['register-form-input-container']}>
                <div className={styles['date-container']}>
                  <p className={styles['date-label']}>Day</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <p onClick={() => setOpenDate(true)} style={{borderColor: trigger && !date ? '#fb5b5b' : '#000C78'}} className={styles['date-input-shape']}>
                      {date ? `${new Date(date).getDate().toLocaleString('en-US',{minimumIntegerDigits:2})}/${(new Date(date).getMonth() + 1).toLocaleString('en-US',{minimumIntegerDigits:2})}/${new Date(date).getFullYear()}` : <span style={{fontFamily:'Schoolbell',color:'#b3b7bf'}}>Select Day</span>}
                      <img src={dateIcon} alt='select day'/>
                      {openDate &&
                        <div onClick={(e)=>e.stopPropagation()} className={styles['date-calendar']}>
                          <DateCalendar
                            sx={{
                              background: '#fff',
                              '& *': {
                                fontFamily: 'Schoolbell !important',
                                fontSize: '1.5rem !important'
                              }
                            }}
                            views={['day']}
                            minDate={dayjs('2024-07-01')}
                            openTo='day'
                            value={date}
                            onChange={(newValue) => {
                              console.log(newValue)
                              console.log(new Date(date).getDay())
                              setDate(newValue);
                              setOpenDate(false);
                            }}
                          />
                        </div>
                      }
                    </p>
                  </LocalizationProvider>
                  {trigger && !date &&
                    <p className={styles['form-error']}>* Date is required</p>
                  }
                </div>
                <div className={styles['options-container']}>
                  <p className={styles['options-label']}>Hours/ Slots</p>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    disabled={!date}
                    sx={{
                      width: '100%',
                      minHeight: '6rem',
                      background: '#fff',
                      border: '4px solid #000c78',
                      borderRadius: '2.125rem',
                      outline: 'none !important',
                      fontSize: '1.5rem !important',
                      borderColor: trigger && !selectedSlots ? '#fb5b5b' : '#000c78',
                      '& *': {
                        fontFamily: 'Schoolbell !important',
                        fontSize: '1.5rem !important',
                        border: '0 !important',
                        color: '#000c78 !important'
                      },
                      '& p': {
                        fontSize: '1.75rem !important',
                        color: '#b3b7bf !important'
                      }
                    }}
                    displayEmpty
                    value={selectedSlots}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => {
                      if (selected.length === 0) 
                        return (
                          <p>
                            Hours/ Slots
                          </p>
                        );
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: 0.5 }}>
                          <Chip key={selected} label={selected} />
                        </Box>
                      )
                    }}
                  >
                    {date && new Date(date).getDay() === 5 ? availableSlots.friday.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        sx={{
                          fontFamily: 'Schoolbell !important',
                          fontSize: '1.5rem !important',
                          border: '0 !important',
                          color: '#000c78 !important'
                        }}
                      >
                        {name}
                      </MenuItem>
                    )) :
                      availableSlots.rest.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        sx={{
                          fontFamily: 'Schoolbell !important',
                          fontSize: '1.5rem !important',
                          border: '0 !important',
                          color: '#000c78 !important'
                        }}
                      >
                        {name}
                      </MenuItem>
                    ))
                    }
                  </Select>
                  {trigger && !selectedSlots &&
                    <p className={styles['form-error']}>* Slot is required</p>
                  }
                </div>
              </div>
            }
            {selectedExperienceSlots && selectedExperienceSlots === '1 Day Camp (5 Hours)' &&
              <div className={styles['register-form-input-container']}>
                <div className={styles['date-container']}>
                  <p className={styles['date-label']}>Day</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <p onClick={() => setOpenDate(true)} style={{borderColor: trigger && !date ? '#fb5b5b' : '#000C78'}} className={styles['date-input-shape']}>
                      {date ? `${new Date(date).getDate().toLocaleString('en-US',{minimumIntegerDigits:2})}/${(new Date(date).getMonth() + 1).toLocaleString('en-US',{minimumIntegerDigits:2})}/${new Date(date).getFullYear()}` : <span style={{fontFamily:'Schoolbell',color:'#b3b7bf'}}>Select Day</span>}
                      <img src={dateIcon} alt='select day'/>
                      {openDate &&
                        <div onClick={(e)=>e.stopPropagation()} className={styles['date-calendar']}>
                          <DateCalendar
                            sx={{
                              background: '#fff',
                              '& *': {
                                fontFamily: 'Schoolbell !important',
                                fontSize: '1.5rem !important'
                              }
                            }}
                            views={['day']}
                            minDate={dayjs('2024-07-01')}
                            openTo='day'
                            value={date}
                            onChange={(newValue) => {
                              console.log(newValue)
                              console.log(new Date(date).getDay())
                              setDate(newValue);
                              setOpenDate(false);
                            }}
                          />
                        </div>
                      }
                    </p>
                  </LocalizationProvider>
                  <p className={styles['date-label']}>The time will be from 02:00 PM to 07:00 PM</p>
                  {trigger && !date &&
                    <p className={styles['form-error']}>* Date is required</p>
                  }
                </div>
              </div>
            }
            {selectedExperienceSlots && selectedExperienceSlots === '3 Days Camp (5 Hours Daily)' &&
              <div className={styles['register-form-input-container']}>
                <div className={styles['options-container']}>
                  <p className={styles['options-label']}>Slots</p>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    sx={{
                      width: '100%',
                      minHeight: '6rem',
                      background: '#fff',
                      border: '4px solid #000c78',
                      borderRadius: '2.125rem',
                      outline: 'none !important',
                      fontSize: '1.5rem !important',
                      borderColor: trigger && !selectedSlots ? '#fb5b5b' : '#000c78',
                      '& *': {
                        fontFamily: 'Schoolbell !important',
                        fontSize: '1.5rem !important',
                        border: '0 !important',
                        color: '#000c78 !important'
                      },
                      '& p': {
                        fontSize: '1.75rem !important',
                        color: '#b3b7bf !important'
                      }
                    }}
                    displayEmpty
                    value={selectedSlots}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => {
                      if (selected.length === 0) 
                        return (
                          <p>
                            Select Slot
                          </p>
                        );
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: 0.5 }}>
                          <Chip key={selected} label={selected} />
                        </Box>
                      )
                    }}
                  >
                    {
                      availableSlots.threeDays.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          sx={{
                            fontFamily: 'Schoolbell !important',
                            fontSize: '1.5rem !important',
                            border: '0 !important',
                            color: '#000c78 !important'
                          }}
                        >
                          {name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                  <p className={styles['date-label']}>The time will be from 02:00 PM to 07:00 PM</p>
                  {trigger && !selectedSlots &&
                    <p className={styles['form-error']}>* Slot is required</p>
                  }
                </div>
              </div>
            }
          </div>
          <div className={styles['register-form-input-wrapper']}>
            <p className={styles['register-form-text']}>Do you want to add sports activity to your experience? (we offer functional training, freestyle football and gymnastics) by <a href='https://neo-well.com/' target='_blank'>Neowell</a>.</p>
            <div className={styles['register-form-input-container-radio']}>
              <Radio handleClick={chooseNeoWell} selected={neoWell} id={'newowell-yes'} value={true} label={'Yes'} name={'neoWell'} />
              <Radio handleClick={chooseNeoWell} selected={neoWell} id={'neowell-no'} value={false} label={'No'} name={'neoWell'} />
            </div>
            {trigger && neoWell === undefined && (
              <p className={styles['form-error']}>* you must select an option</p>
            )}
          </div>
          {activity &&
            <div className={styles['register-form-input-wrapper']}>
              <p className={styles['register-form-text']}>Camp Activity preference:</p>
              {activity.map((item) => (
                <FormCheckbox key={item.id} id={item.id} value={item.id} onChange={handleActivityCheckboxesChange} name={item.id}  label={item.name} />
              ))}
              {trigger && selectedActivity.length === 0 &&
                <p className={styles['form-error']}>* select at least one option</p>
              }
            </div>
          }
          <div className={styles['register-form-input-wrapper']}>
            <p className={styles['register-form-text-small']}>Camper Needs</p>
            <p className={styles['register-form-text']}>Please let us know if your child has any specific needs, allergies, or medical conditions that require special care during the camp. This information will help us ensure your child has a safe and enjoyable experience.</p>
          </div>
          <div className={styles['register-form-input-wrapper']}>
            <p className={styles['register-form-text']}>Does your child have any allergies?</p>
            <div className={styles['register-form-input-container-radio']}>
              <Radio handleClick={chooseAllergies} selected={allergies} id={'allergies-yes'} value={true} label={'Yes'} name={'allergies'} />
              <Radio handleClick={chooseAllergies} selected={allergies} id={'allergies-no'} value={false} label={'No'} name={'allergies'} />
            </div>
            {trigger && allergies === undefined &&
              <p className={styles['form-error']}>* you must select an option</p>
            }
            {allergies &&
              <FormInput maxLength={100} formRegister={allergiesExtraRegister} placeholder={'Please specify'} />
            }
          </div>
          <div className={styles['register-form-input-wrapper']}>
            <p className={styles['register-form-text']}>Does your child have any medical conditions that require special care?</p>
            <div className={styles['register-form-input-container-radio']}>
              <Radio handleClick={chooseSpecial} selected={special} id={'special-yes'} value={true} label={'Yes'} name={'special'} />
              <Radio handleClick={chooseSpecial} selected={special} id={'special-no'} value={false} label={'No'} name={'special'} />
            </div>
            {trigger && special === undefined &&
              <p className={styles['form-error']}>* you must select an option</p>
            }
            {special &&
              <FormInput maxLength={100} formRegister={specialExtraRegister} placeholder={'Please specify'} />
            }
          </div>
          <div className={styles['register-form-input-wrapper']}>
            <p className={styles['register-form-text']}>Does your child require any medications during camp?</p>
            <div className={styles['register-form-input-container-radio']}>
              <Radio handleClick={chooseMeds} selected={meds} id={'meds-yes'} value={true} label={'Yes'} name={'meds'} />
              <Radio handleClick={chooseMeds} selected={meds} id={'meds-no'} value={false} label={'No'} name={'meds'} />
            </div>
            {trigger && meds === undefined &&
              <p className={styles['form-error']}>* you must select an option</p>
            }
            {meds &&
              <FormInput maxLength={100} formRegister={medsExtraRegister} placeholder={'Please specify'} />
            }
          </div>
          <div className={styles['register-form-input-wrapper']}>
            <p className={styles['register-form-text-small']}>Photo/Video Release</p>
            <p className={styles['register-form-text']}>We may take some images and videos of the campers while participating in the various stations for promotional and marketing purposes, such as posting on our social media pages. These images and videos will not be used for any commercial purposes and will not generate any profit.</p>
          </div>
          <div className={styles['register-form-input-wrapper']}>
            <p className={styles['register-form-text']}>I grant permission for STEMPOWERS camp to take images and videos of my child and use them for promotional purposes.</p>
            <div className={styles['register-form-input-container-radio']}>
              <Radio handleClick={chooseMedia} selected={media} id={'media-yes'} value={true} label={'Yes'} name={'media'} />
              <Radio handleClick={chooseMedia} selected={media} id={'media-no'} value={false} label={'No'} name={'media'} />
            </div>
            {trigger && media === undefined &&
              <p className={styles['form-error']}>* you must select an option</p>
            }
          </div>
          <button
            onClick={() => setTrigger(true)}
            className={styles['register-form-action']}
            type='submit'
            disabled={loading}
          >
            {loading ? <Loader/> : 'Register'}
          </button>
        </form>
      </div>
    </>
  )
}
