import styles from './index.module.scss';
import ourPrograms from '../../assets/images/our-projects.png';
import ufo from '../../assets/icons/ufo.svg';
import scope from '../../assets/icons/scope.svg';
import selected from '../../assets/icons/selected-2.svg';
import downArrow from '../../assets/images/down-arrow.png';
export default function OurPrograms({summerProgramRef}) {
  return (
    <div ref={summerProgramRef} id='summer_program' className={styles['our-programs']}>
      <div className={styles['our-programs-content']}>
        <h3 className={styles['our-programs-content-title']}>Our unique STEMPOWERS Summer program </h3>
        <div className={styles['our-programs-content-wrapper']}>
          <p className={styles['our-programs-content-text']}>Is your child looking for thrilling experiences beyond the ordinary this summer?</p>
          <p className={styles['our-programs-content-text']}>Our STEMPOWERS innovative edutainment summer program is designed to take your children away on an extraordinary mission filled with discovery, laughter, and endless fun.</p>
        </div>
        <div>
          <img src={ufo} alt='' />
          <p>
            The Program is super powered with a wide range of fun Edutainment
            <span className={styles['selected']}>
              Stations.
              <img src={selected} alt='' />
              <img src={downArrow} alt='' className={styles['down-arrow']} />
            </span>
          </p>
        </div>
        <div>
          <img src={scope} alt='' />
          <p>Each station offers unique activities designed to ignite children's powers of imagination and transform them into explorers, engineers, and budding scientists</p>
        </div>
      </div>
      <img src={ourPrograms} alt='' className={styles['our-programs-image']}/>
    </div>
  )
}
