import styles from './index.module.scss';
import checkMarkImage from '../../assets/icons/check.svg';

export function FormCheckbox(props) {
  const { label, id, name, value, onChange,error } = props;

  return (
    <>
      <div className={styles['container']}>
      <label htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          hidden
          className={styles['check-box']}
        />
        <span className={styles['checkmark']}>
          <img src={checkMarkImage} alt="check" />
        </span>
        {label}
        {error &&
          <p className={styles['check-box-error']}>{error.message}</p>  
        }
      </label>
      </div>
    </>
  );
}
