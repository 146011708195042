import styles from './index.module.scss';

export function Radio({handleClick,id,value,label,selected,children,name}) {
  return (
    <div className={styles['container']}>
      <input
        className={styles['radio-input']}
        type="radio"
        id={id.toString()}
        name={name}
        value={value}
        hidden
        checked={selected === value}
        onChange={() => handleClick(value)}
      />
      <label
        className={styles['radio-input-lable']}
        htmlFor={id.toString()}
      >
        <div className={styles['radio-input-content']}>
          <span className={styles['radio-input-lable-text']}>
            {label}
          </span>
        </div>
        {children}
      </label>
    </div>
  );
}

export default Radio;