import styles from './index.module.scss';
import closeIcon from '../../assets/icons/close.svg';
import announcement from '../../assets/images/announcement.png';
import announcementTwo from '../../assets/images/announcement-two.png';
import { useNavigate } from 'react-router-dom';

export default function Announcement({handleCloseModal}) {
  const navigate = useNavigate();

  return (
    <div className={styles['modal']}>
      <div className={styles['modal-container']}>
        <div className={styles['modal-content']}>
          <img src={announcement} className={styles['modal-decoration-one']} alt='' />
          <img src={announcementTwo} className={styles['modal-decoration-two']} alt='' />
          <img className={styles['modal-icon']} onClick={handleCloseModal} src={closeIcon} alt='close' />
          <h3 className={styles['modal-content-title']}>Our Dynamic 3-Day Camp Experience!</h3>
          <p className={styles['modal-content-text']}><span>Enroll Your Kids Now for 'Harness the Sun'</span> Watch them learn about sustainability, designing solar powered models, exploring future careers through Interactive Activities, Designing Model Homes and Exploring Solar Panel Efficiency.</p>
          <div>
          <p className={styles['modal-content-text']}><span>Choose Your Dates:</span>Aug 13-15 (Tues-Thurs) or Aug 16-18 (Fri-Sun)</p>
          <p className={styles['modal-content-text']}><span>Location:</span>at Vida Hotel – Marassi!!</p>
          </div>
          <button onClick={()=>navigate('register')} className={styles['modal-action']}>Register Now for limited spots</button>
        </div>
      </div>
    </div>
  )
}
