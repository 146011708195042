import styles from './index.module.scss';
import closeIcon from '../../assets/icons/close-white.svg';
import { useEffect, useRef } from 'react';

export function Snackbar({snackbar,setSnackbar}) {
  const { display, message, variant } = snackbar;
  const timerId = useRef();
  const handleCloseSnackbar = () => {
    setSnackbar({
      display: false,
      variant: 'success',
      message: ''
    })
  }

  useEffect(() => {
    if (display) {
      timerId.current = setTimeout(() => {
        handleCloseSnackbar();
        clearTimeout(timerId);
      },5000)
    } else if (timerId?.current) {
      clearTimeout(timerId?.current);
    }
  },[display])
  
  return (
    <div className={[
      styles['snackbar'],
      variant === 'error' && styles['snackbar-error'],
      display && styles['snackbar-show']
    ].join(' ')}>
      <p className={styles['snackbar-text']}>
        {message}
      </p>
      <button onClick={handleCloseSnackbar} className={styles['snackbar-close']}>
        <img className={styles['snackbar-close-icon']} src={closeIcon} alt='close'/>
      </button>
    </div>
  )
}

export default Snackbar;
