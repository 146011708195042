 
import styles from './index.module.scss';
import Navbar from '../components/navbar';
import Footer from '../components/footer';

export default function Layout({children, contactRef}) {
  return (
    <main className={styles['layout']}>
      <Navbar />
      <section className={styles['layout-content']}>
        {children}
      </section>
      <Footer contactRef={contactRef} />
    </main>
  )
}
