import styles from './index.module.scss';
import sctatsh from '../../assets/icons/scratsh.svg';
import { useNavigate } from 'react-router-dom';
import tabImage from '../../assets/images/tab-image.png';

export default function ProgramStations({ stations,stationsRef, selectedStation, setSelectedStation, selectedTab, setSelectedTab }) {
  const navigate = useNavigate();

  return (
    <div ref={stationsRef} className={styles['stations']}>
      <h3 className={styles['stations-title']}>STEMPOWERS stations</h3>
      <div className={styles['stations-tabs']}>
        <p onClick={() => {
          setSelectedTab('ONEDAY')
          setSelectedStation(stations.find(station => station.type === 'ONEDAY'))
        }} className={selectedTab === 'ONEDAY' ? styles['selected-tab'] : ''}>Day Experience</p>
        <p onClick={() => {
          setSelectedTab('MULTIPLEDAYS');
          setSelectedStation(stations.find(station => station.type === 'MULTIPLEDAYS'))
        }} className={selectedTab === 'MULTIPLEDAYS' ? styles['selected-tab'] : ''}>3 Days Camp</p>
      </div>
      <div className={styles['stations-container']}>
        <ul className={styles['stations-tab-list']}>
          {stations && stations.filter(station => station.type === selectedTab).map((station, index) => {
            return (
              <li onClick={()=>{setSelectedStation(station)}} key={index} className={`${styles['stations-tab-list-item']} ${selectedStation?.id === station.id ? styles['selected'] : ''}`}>
                <p>
                  <img src={sctatsh} alt='' />
                  {`Station #${index + 1}`}
                </p>
                <p>{station.title}</p>
              </li>
            )
          })}
        </ul>
        {selectedStation && (
        <div className={styles['stations-tab-container']}>
          <h5 className={styles['stations-tab-title']}>
            {selectedStation.title} Station
          </h5>
          <div className={styles['stations-tab-content']}>
            <p className={styles['stations-tab-special']}>{selectedStation.question}</p>
            <p className={styles['stations-tab-text']} dangerouslySetInnerHTML={{ '__html': selectedStation.description }}/>
          </div>
          {selectedStation.age_ranges &&
            <div className={styles['stations-tab-age']}>
              <p className={styles['stations-tab-age-title']}>age range</p>
              <p className={styles['stations-tab-age-text']}>
                {selectedStation.age_ranges}
              </p>
            </div>
          }
          {selectedStation.benefits &&
            <div className={styles['stations-tab-benifits']}>
              <p className={styles['stations-tab-benifits-title']}>station benefits</p>
              <p className={styles['stations-tab-benifits-text']}>
                {selectedStation.benefits}
              </p>
            </div>
          }
          {selectedStation.big_ideas &&
              <div className={styles['stations-tab-age']}>
              <p className={styles['stations-tab-benifits-title']}>Big Ideas</p>
              <p className={styles['stations-tab-age-text']}>
                {selectedStation.big_ideas}
              </p>
            </div>
          }
            {selectedStation.design_challenge &&
              <div className={styles['stations-tab-age']}>
                <p className={styles['stations-tab-special']}>Design Challenge</p>
                <p className={styles['stations-tab-age-text']}>
                  {selectedStation.design_challenge}
                </p>
              </div>
            }
            {selectedTab === 'ONEDAY' && (
              <img src={tabImage} alt='' className={styles['stations-tab-image']} />
            )}
          <button disabled={selectedTab === 'MULTIPLEDAYS' && selectedStation.title === 'What a Waste'} onClick={()=> navigate('/register')} className={styles['stations-tab-action']}>register now</button>
        </div>
        )}
      </div>
    </div>
  )
}
