import styles from './index.module.scss';
import spark from '../../assets/icons/flash.svg';
import heroLeftImageAnimation from '../../assets/images/hero-left.gif';
import heroLeftImage from '../../assets/images/hero-left.png';
import heroRightImageAnimation from '../../assets/images/hero-right.gif';
import heroRightImage from '../../assets/images/hero-right.png';
import heroFirst from '../../assets/images/hero-first.gif';
import heroSecond from '../../assets/images/hero-second.png';
import heroSecondResponsive from '../../assets/images/hero-second-responsive.png';
import heroThird from '../../assets/images/hero-third.gif';
import more from '../../assets/icons/more.svg';
import underline from '../../assets/icons/underline.svg';
import topArrow from '../../assets/icons/top-arrow.svg';
import selected from '../../assets/icons/selected.svg';
import { useIsMobile } from '../../hooks/isMobile';
import { useNavigate } from 'react-router-dom';

export default function Hero() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <div className={styles['hero']}>
      <img src={isMobile ? heroLeftImage : heroLeftImageAnimation} className={styles['hero-left-image']} alt='' />
      <img src={isMobile ? heroRightImage : heroRightImageAnimation} className={styles['hero-right-image']} alt='' />
      <p className={styles['hero-text']}>stem <img className={styles['hero-text-image']} src={spark} alt='' /> powers</p>
      <h2 className={styles['hero-title']} data-text={'unleash young minds potentials'}>unleash young <br/> minds potentials</h2>
      <div className={styles['hero-content']}>
        <div>
          <img src={heroFirst} alt='' />
          <p>
            We turn STEM learning into a <span className={styles['underline']}>special<img src={underline} alt='' /></span> <span className={styles['top-arrow']}>adventure. <img src={topArrow} alt='' /></span>
          </p>
          <a onClick={()=>navigate('/#why_join')}>
            read more
            <img src={more} alt='' />
          </a>
        </div>
        <div>
          <img src={!isMobile ? heroSecond : heroSecondResponsive} alt='' />
          <p>
            <span className={styles['selected']}>STEM <img className={styles['selected-img']} src={selected} alt='' /></span> programs that prepare young minds for the challenges of tomorrow.
          </p>
          <a onClick={()=>navigate('/#program_details')}>
            program details
            <img src={more} alt='' />
          </a>
          <img src={heroThird} alt='' />
        </div>
      </div>
    </div>
  )
}
