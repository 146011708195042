import styles from './index.module.scss';
import { useState } from 'react';
import logo from '../../assets/images/logo.png';
import close from '../../assets/icons/close.svg';
import { useNavigate } from 'react-router-dom';

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleToogleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }
  const handleGoToRegister = () => {
    setIsMenuOpen(false)
    navigate('/register')
  }

  return (
    <nav className={styles['navbar']}>
      <div className={styles['navbar-logo']}>
        <img onClick={()=>navigate('/')} className={styles['navbar-logo-icon']} src={logo} alt='Logo' />
      </div>
      <ul className={styles['navbar-links']}>
        <li onClick={()=>navigate('/#summer_program')} className={styles['navbar-link']}>stem summer program</li>
        <li onClick={()=>navigate('/')} className={styles['navbar-link']}>stem winter program<span>launching soon!</span></li>
        {/* <li className={styles['navbar-link']}>about</li> */}
      </ul>
      <button onClick={handleGoToRegister} className={styles['navbar-action']}>register now</button>
      <button onClick={handleToogleMenu} className={styles['navbar-menu-action']}>
      </button>
      {
        isMenuOpen && (
          <div className={styles['navbar-menu']}>
            <img src={close} onClick={handleToogleMenu} className={styles['navbar-menu-close']} alt='' />
            <ul className={styles['navbar-menu-links']}>
              <li onClick={()=>navigate('/#summer_program')} className={styles['navbar-menu-link']}>stem summer program</li>
              <li onClick={()=>navigate('/')} className={styles['navbar-menu-link']}><span>launching soon!</span> stem winter program</li>
              {/* <li className={styles['navbar-menu-link']}>about</li> */}
              <li className={styles['navbar-menu-link']}>
                <button onClick={handleGoToRegister} className={styles['navbar-menu-link-action']}>register now</button>
              </li>
            </ul>
          </div>
        )
      }
    </nav>
  )
}
