import { useEffect, useRef, useState } from 'react';
import Discounts from '../../components/discounts';
import Hero from '../../components/hero';
import OurPrograms from '../../components/ourPrograms';
import ProgramStations from '../../components/programStations';
import ReadyToUnlock from '../../components/readyToUnlock';
import WhyJoin from '../../components/whyJoin';
import styles from './index.module.scss';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Announcement from '../../components/announcement';
import VideosContainer from '../../components/videosContainer';

export default function Home({contactRef, showModal, setShowModal}) {
  const [stations, setStations] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [selectedTab, setSelectedTab] = useState('ONEDAY');
  const location = useLocation();
  const summerProgramRef = useRef(null);
  const renderCount = useRef(1);
  const stationsRef = useRef(null);
  const whyJoinRef = useRef(null);

  const handleGetStations = async () => {
    try {
      const response = await fetch('https://app.stempowers.me/api/camp_manager/program_stations?type=');
      const data = await response.json();
      setStations(data.results);
      setSelectedStation(data.results[0]);
      const multipleDaysResponse = await fetch('https://app.stempowers.me/api/camp_manager/program_stations?type=MULTIPLEDAYS');
      const multipleDaysData = await multipleDaysResponse.json();
      setStations(prevState => [...prevState, ...multipleDaysData.results]);
    } catch (error) {
      console.error(error);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  }
  
  useEffect(() => {
    if (renderCount.current === 1) {
      handleGetStations();
    }
    renderCount.current++;
  }, []);
  
  useEffect(() => {
    if((location.hash === '#summer_program' || location.hash === '#program_details') && summerProgramRef.current) {
      summerProgramRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (location.hash === '#why_join' && whyJoinRef.current) {
      whyJoinRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (location.hash === '#contact' && contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (stations && stationsRef.current) {
      if(location.hash) {
        const station = stations.find(station => station.title.toLowerCase() === location.hash.replaceAll('#', '').replaceAll('_', ' '));
        setSelectedTab('ONEDAY')
        setSelectedStation(station)
        stationsRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location])
  
  return (
    <>
      <Helmet>
				<title>STEMPOWERS | UNLEACH YOUNG MINDS POTENTIALS</title>
			</Helmet>
      <div className={styles['home']}>
        <Hero />
        <WhyJoin whyJoinRef={whyJoinRef} />
        <div className={styles['home-content']}>
          <OurPrograms summerProgramRef={summerProgramRef}  />
          <ProgramStations selectedTab={selectedTab} setSelectedTab={setSelectedTab} selectedStation={selectedStation} setSelectedStation={setSelectedStation} stationsRef={stationsRef} stations={stations} />
          <ReadyToUnlock />
          <VideosContainer/>
          <Discounts />
        </div>
      </div>
      {showModal &&
        <Announcement handleCloseModal={handleCloseModal} />
      }
    </>
  )
}
