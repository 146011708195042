import styles from './index.module.scss';
import readyRight from '../../assets/images/ready-right.png';
import readyLeft from '../../assets/images/ready-left.png';
import readyImage from '../../assets/images/ready-image.png';
import { useNavigate } from 'react-router-dom';

export default function ReadyToUnlock() {
  const navigate = useNavigate();
  return (
    <div className={styles['ready']}>
      <img src={readyRight} className={styles['ready-right']} alt=''/>
      <img src={readyLeft} className={styles['ready-left']} alt=''/>
      <h3 className={styles['ready-title']}>
        <span>Registration is now open!</span>
        <img src={readyImage} className={styles['ready-image']} alt='' />
        Ready to unlock your child’s coolest powers?
      </h3>
      <p className={styles['ready-text']}>Don't miss out on this extraordinary opportunity to create unforgettable memories and empower your child to embrace the joy of discovery with our STEMPOWERS program!  Whether it’s coding, robotics, solving Mysteries or STEM powerful Challenges, we’ve got it all. </p>
      <button onClick={()=>navigate('/register')} className={styles['ready-action']}>register now</button>
    </div>
  )
}
