import styles from './index.module.scss';
import videoOne from '../../assets/videos/video-one.mp4';
import videoTwo from '../../assets/videos/video-two.mp4';
import videoThree from '../../assets/videos/video-three.mp4';
import videoFour from '../../assets/videos/video-four.mp4';
import videoFive from '../../assets/videos/video-five.mp4';
import videoSix from '../../assets/videos/video-six.mp4';
import videoSeven from '../../assets/videos/video-seven.mp4';
import { useState } from 'react';
import Video from '../video';
import backArrow from '../../assets/icons/video-back.svg';
import forwardArrow from '../../assets/icons/video-forward.svg';
import VideoModal from '../videoModal';

export default function VideosContainer() {
  const [step, setStep] = useState(1);
  const isMobile = window.innerWidth < 768;
  const [openVideo, setOpenVideo] = useState(false);
  const [src, setSrc] = useState('');

  const handleForward = () => {
    if(step < 5 && !isMobile)
      setStep(prev => prev + 1)
    else if (isMobile && step < 7)
      setStep(prev => prev + 1)
  };
  const handleBack = () => {
    if(step > 1 && !isMobile)
      setStep(prev => prev - 1)
    else if (isMobile && step > 1)
      setStep(prev => prev - 1)
  };
  const handlePlay = (src) => {
    setOpenVideo(true);
    setSrc(src)
  };
  const handleCloseModal = (e) => {
    e.stopPropagation();
    setOpenVideo(false);
    setSrc('');
  }

  return (
    <>
      <div className={styles['videos']}>
        <h3 className={styles['videos-title']}>
          <span>join us now!</span>
          Program’s videos
        </h3>
        <div className={styles['videos-actions']}>
          <button className={styles['videos-action']} disabled={step === 1} onClick={handleBack}>
            <img src={backArrow} alt='' />
          </button>
          <button className={styles['videos-action']} disabled={(step === 5 && !isMobile) || (isMobile && step === 7)} onClick={handleForward}>
            <img src={forwardArrow} alt='' />
          </button>
        </div>
        {!isMobile &&
          <div className={styles['videos-container']}>
            {step === 1 &&
              <div className={styles['videos-container-video']}>
                <Video handlePlay={()=>handlePlay(videoOne)} src={videoOne} />
              </div>
            }
            {step <= 2 &&
              <div className={styles['videos-container-video']}>
                <Video handlePlay={()=>handlePlay(videoTwo)} src={videoTwo} />
              </div>
            }
            {step <= 3 &&
              <div className={styles['videos-container-video']}>
                <Video handlePlay={()=>handlePlay(videoThree)} src={videoThree} />
              </div>
            }
            {step > 1 && step <= 4 &&
              <div className={styles['videos-container-video']}>
                <Video handlePlay={()=>handlePlay(videoFour)} src={videoFour} />
              </div>
            }
            {step > 2 && step <= 5 &&
              <div className={styles['videos-container-video']}>
                <Video handlePlay={()=>handlePlay(videoFive)} src={videoFive} />
              </div>
            }
            {step > 3 &&
              <div className={styles['videos-container-video']}>
                <Video handlePlay={()=>handlePlay(videoSix)} src={videoSix} />
              </div>
            }
            {step > 4 &&
              <div className={styles['videos-container-video']}>
                <Video handlePlay={()=>handlePlay(videoSeven)} src={videoSeven} />
              </div>
            }
          </div>
        }
        {isMobile &&
          <div className={styles['videos-container']}>
            {step === 1 &&
              <div className={styles['videos-container-video']}>
                <Video handlePlay={()=>handlePlay(videoOne)} src={videoOne} />
              </div>
            }
            {step === 2 &&
              <div className={styles['videos-container-video']}>
                <Video handlePlay={()=>handlePlay(videoTwo)} src={videoTwo} />
              </div>
            }
            {step === 3 &&
              <div className={styles['videos-container-video']}>
                <Video handlePlay={()=>handlePlay(videoThree)} src={videoThree} />
              </div>
            }
            {step === 4 &&
              <div className={styles['videos-container-video']}>
                <Video handlePlay={()=>handlePlay(videoFour)} src={videoFour} />
              </div>
            }
            {step === 5 &&
              <div className={styles['videos-container-video']}>
                <Video handlePlay={()=>handlePlay(videoFive)} src={videoFive} />
              </div>
            }
            {step === 6 &&
              <div className={styles['videos-container-video']}>
                <Video handlePlay={()=>handlePlay(videoSix)} src={videoSix} />
              </div>
            }
            {step === 7 &&
              <div className={styles['videos-container-video']}>
                <Video handlePlay={()=>handlePlay(videoSeven)} src={videoSeven} />
              </div>
            }
          </div>
        }
      </div>
      {openVideo &&
        <VideoModal src={src} handleCloseModal={handleCloseModal} />
      }
    </>
  )
}
