import styles from './index.module.scss';
import scratsh from '../../assets/icons/scratsh.svg';

export default function Discounts() {
  
  return (
    <div className={styles['discounts']}>
      <h3 className={styles['discounts-title']}>Program Discounts &<br/>Promotions</h3>
      <div className={styles['discounts-cards']}>
        <div className={styles['discounts-card']}>
          <h4 className={styles['discounts-card-title']}>
            <img src={scratsh} className={styles['discounts-card-title-image']} alt='' />
            Rate
          </h4>
          <ul className={styles['discounts-card-text-list']}>
            <li>2 Hours Slot: <span className='price'>EGP950</span></li>
            <li>1 Day Camp (5H): <span className='price'>EGP1800</span></li>
            <li>3 Days Camp (5H/day): <span className='price'>EGP3500</span></li>
            <li>1 Hour Fitness/Freestyle session: <span className='price'>EGP500</span></li>
          </ul>
        </div>
        <div className={styles['discounts-card']}>
          <h4 className={styles['discounts-card-title']}>
            <img src={scratsh} className={styles['discounts-card-title-image']} alt='' />
            Squads discount
          </h4>
          <p className={styles['discounts-card-text']}>Bring your squad to STEMPOWERS Community and enjoy a 15% special group discount on specific Programs</p>
        </div>
        <div className={styles['discounts-card']}>
          <h4 className={styles['discounts-card-title']}>
            <img src={scratsh} className={styles['discounts-card-title-image']} alt='' />
            Siblings' discounts
          </h4>
          <p className={styles['discounts-card-text']}>Bring your family to STEMPOWERS Community and enjoy 5% on each sibling </p>
        </div>
        <div className={styles['discounts-card']}>
          <h4 className={styles['discounts-card-title']}>
            <img src={scratsh} className={styles['discounts-card-title-image']} alt='' />
            Early Birds discounts
          </h4>
          <p className={styles['discounts-card-text']}>Places are limited! Register now to benefit from EARLY BIRDS DISCOUNT up to 10%</p>
        </div>
      </div>
    </div>
  )
}
